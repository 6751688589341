import React, { Component } from 'react';
import logo from './logo-zappy.png';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 d-flex align-items-center">
              <div className="w-100">
                <img src={logo} alt="Zappy" className="img-fluid"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
